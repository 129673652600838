import React from 'react';
import { images } from '../../constants';
import { Customers } from '../../components';
import './ConsutlHero.css';
import Headings from '../../components/Headings/Headings';

const ConsultHero = () => {
  const handleButtonClick = () => {
    window.open("https://calendly.com/techlara/30min", "_blank");
  };

  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title"> AI-konsult för ditt företag</h1>
          <p> Trött på repetativa uppgifter? Techlära är här för att underlätta din vardag med den senaste AI-tekniken.</p>
          <button className="btn-green" onClick={handleButtonClick}> Boka ett kostnadsfritt möte </button>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.johannesProfile} alt="AI expert Johannes Olsson" title="AI expert Johannes Olsson"/>
        </div>
      </div>
      
      <div className="customers-container">
        <Customers />
      </div>
      
      <div className="intro-text">
        <Headings title="Vad vi kan hjälpa ert företag med" text="Effektiv AI-konsultering" />
        <p>Vi hjälper dig utnyttja kraften i artificiell intelligens för att effektivisera processer, öka produktiviteten och förbättra beslutsfattandet. </p>
        <p>Vårt mål är att förändra er verksamhet med smarta AI-val. Låt oss tillsammans se till att ditt företag blir av med repetativa uppgifter så ni kan fokusera på det ni är bra på! </p>
        {/* <p>Johannes arbetade tillsammans med Erik på Cykel & Natur där han hjälpte till med marknadsföring och lite andra digitala lösningar. Erik kom på att han hade ett problem. 
        Att skapa skyltar för hand tog tid och det blev inte alltid så snyggt, det är svårt att skriva snyggt! Johannes kom då på en idé. Jag bygger en sida där du kopierar länken från cykeln på hemsidan
        Programmet hämtar all information om cykeln, som pris, rabatterat pris och namnet på cykeln och skapar en enkel sida som du snabbt kan printa ut. 3h senare var sidan klar och Erik kunde 
        enkelt skapa en snygg skylt för varje cykel.</p> */}
      </div>
    </div>
  )
}

export default ConsultHero;