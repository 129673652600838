import React from 'react'
import { Headings } from '../../components';
import './Contact.css';
import emailjs from 'emailjs-com';
import { useEffect, useState } from "react";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_KEY), []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const templateParams = {
      to_name: "Johannes",
      from_name: name,
      from_email: email,
      message: message
    };

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email successfully sent!');
      }, (err) => {
        console.error('FAILED...', err);
        alert('Failed to send the email. Please try again.' + err.message);
      });
  };

  return (
    <div classNameName="section-padding">
      <Headings title="Anlita en AI-expert" text="Vi hjälper er med AI" />
      
      <div className="contact" id="quote">

        Vill du bli av med repetativa uppgifter, träna en egen chattbot, bygga en AI-app/hemsida, eller boka en föreläsning?
        <br/>
        <br/>
        Du kan boka ett möte med vår AI-expert Johannes via calendlylänken nedan:
        <br/>
        <br/> 
        <a className="big-link" href="https://calendly.com/techlara/30min" target="_blank" rel="noopener noreferrer">Boka kostnadsfritt möte</a>
        <br/>
        <br/> 

        Eller så fyller du i dina uppgifter nedan så kontaktar vi dig.
        <div className="row">
          <div className="col-md-6 col-12">
            <form>
              {/* <div class="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                <label className="form-check-label" for="inlineRadio1">Jag vill boka ett webinar</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                <label className="form-check-label" for="inlineRadio2">Jag vill ha en offert</label>
              </div> */}

              <div className="form-group">
                <label>Namn*</label>
                <input className="form-control" type="text" placeholder="Namn" onChange={handleNameChange}/>
              </div>
              <div className="form-group">
                <label>Email*</label>
                <input className="form-control" type="email" placeholder="Email" onChange={handleEmailChange}/>
              </div>

              <div class="form-group">
                <label for="exampleFormControlTextarea1"> Meddelande</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleMessageChange}></textarea>
              </div>
              <button type="submit" class="btn-green w-100" onClick={handleSubmit}>Skicka</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact