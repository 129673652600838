import NewsData from '../constants/NewsData';

const internalLink = ({ type, id }) => {
  const newsItem = NewsData.find(item => item.id === id);

  if (!newsItem) {
    return "";
  }

  const url = createUrlFromHeader(newsItem.header);

  return (
    type ? `/${type}/${url}` : `/${url}`
  );
};


const createUrlFromHeader = (header) => {
  return header.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
};

export { internalLink, createUrlFromHeader };
