import React from 'react';
import './IconScroll.css';
import images from '../../constants/images';

const Customers = () => {
    return (
        <div className="partner-container">
            Kunder vi hjälpt: 
            <div className="tscroll">
                <a href="https://cykelnatur.se" target="_blank" rel="noopener noreferrer">
                    <img src={images.cykelNatur} alt="kund cykel natur" imgAlt="kund cykel natur" title="kund cykel natur" />
                </a>
                <a href="https://golow.eu/" target="_blank" rel="noopener noreferrer">
                    <img src={images.golowLogo} alt="kund golow" imgAlt="kund golow" title="kund golow" />
                </a>
                <a href="https://www.mysallitaliana.com/" target="_blank" rel="noopener noreferrer">
                    <img src={images.mysItalia} alt="kund mys italia" imgAlt="kund mys italia" title="kund mys italia" />
                </a>
                <a href="https://husstainability.se" target="_blank" rel="noopener noreferrer">
                    <img src={images.husstainability} alt="kund husstainability" imgAlt="kund husstainability" title="kund husstainability" />
                </a>
                <a href="https://lillastaderiet.se" target="_blank" rel="noopener noreferrer">
                    <img src={images.lillaStaderiet} alt="kund lilla staderiet" imgAlt="kund lilla staderiet" title="kund lilla staderiet" />
                </a>
            </div>
        </div>
    );
};

export default Customers;